
.compiler-container {
    text-align: center;
}

.row-compiler.row {
    display: grid;
    justify-content: center;
}

.project-description {
    padding-top: 20px;
    padding: 30px;
    text-align: left;
    font-size: calc(13px + 0.4vw);
}

.compiler-image {
    max-width: 100%;
    height: auto;
    width: auto; /* ie8 */
}