
.sierra-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.sierra-image {
    max-width: 100%;
    height: auto;
    width: auto; /* ie8 */
}