
.contact-container {
    text-align: center;
    font-size: 30px;
}

.contact-content {
    display: inline-block;
    text-align: left;
}

.images-container {
    padding-top: 25px;
}
