
    @import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');
    .container {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family: 'Mukta';
    }

    li {
        list-style: none;
    }

    a{ 
        text-decoration: none;
        color: black;
    }

    .navbar {
        min-height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nav-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
        font-weight: 530;
        font-size: 20px;
    }

    .nav-branding {
        font-size: calc(2rem + 0.4vw);
        font-weight: 400;
    }

    @media screen and (max-width: 800px){

        .menu-icon {
            transform: scale(1.5);
            color: black;
        }

        .mobile-nav-element {
            font-family: 'Mukta';
            color: rgb(0, 0, 0);   
            font-size: calc(30px + 0.4vw);
            font-weight: 530;  
        }

        .x-position { 
            float: right;
        }
        
        .x-icon {
            transform: scale(2.0);
            color: black;
        }


    }











