

.format {
    margin-left: 1rem;
}

.project-box {
    border: 1px solid black;
}

.project_photo {
    max-width: 100%;
    height: auto;
    width: auto; /* ie8 */
}

.row-work.row {
    display: flex;
    justify-content: center;
}

.col-project {
    padding: 20px;
}


@media screen and (max-width: 935px){
    .row-work.row {
        display: grid;
    }
}
