
.about-container {
    text-align: center;
}


.text-for-about {
    padding-top: 20px;
    padding: 30px;
    text-align: left;
    font-size: calc(15px + 0.4vw);
}

.row-about.row {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 830px){
    .row-about.row {
        display: grid;
    }
}
